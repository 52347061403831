import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"

const EventsSlugTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const { content, EventSlugImages, EventSlugDetails } = markup.frontmatter
  const eventslugimages = EventSlugImages.blurb

  return (
    <Layout>
      <section className="container EventsSlugTemplate">
        <h1 className="text-center mt-5">{EventSlugDetails.MainTitle}</h1>
        <p className="text-center mb-5 fs-6">{EventSlugDetails.SubTitle}</p>

        <div className="row my-5">
          <div className="col-12 col-lg-8">
            <h3 className="w-75 mb-4">{EventSlugDetails.Title}</h3>
            <div className="date-section">
              {EventSlugDetails.Date} | {EventSlugDetails.Location}
            </div>
            <ReactMarkdown
              className="my-5 fs-6 paragraph-width"
              children={content}
            ></ReactMarkdown>
          </div>

          <div className="col-12 col-lg-4 d-flex flex-column events-slug-images flex-grow-1">
            {eventslugimages.map((item, index) => (
              <div key={`eventslugimages_${index}`}>
                <GatsbyImage
                  image={item.image.childImageSharp.gatsbyImageData}
                  alt={item.imageName}
                  className="h-75"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const EventsSlugQuery = graphql`
  query EventsSlugQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        name
        description
        content
        EventSlugImages {
          blurb {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageName
          }
        }
        EventSlugDetails {
          MainTitle
          SubTitle
          Title
          Date
          Location
        }
      }
    }
  }
`
export default EventsSlugTemplate
